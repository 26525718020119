<template>
    <HeaderVue @go-to-tariffs="goToTariffs()" @togle-profile-modal="toggleProfileModal()" :isAuthenticated="true">
    </HeaderVue>
    <main>
        <section class="main-board user-board">
            <div class="container">
                <div class="user_control">
                    <div class="user-board_back_to_workboard_wrap">
                        <router-link to="../" class="back-to-work-board back-to-work-board_full-w">{{
                            $t('userBackToQuiz')
                            }}</router-link>
                    </div>
                    <nav class="user-board_menu">
                        <div class="one_user_link_wrap">
                            <router-link class="tariffs" to="../tariffs">{{ $t('navTariffs') }}</router-link>
                        </div>
                        <div class="one_user_link_wrap">
                            <router-link class="billing" to="../billing">{{ $t('navBillings') }}</router-link>
                        </div>
                        <div class="one_user_link_wrap one_user_link_wrap_partnership">
                            <router-link class="partnership" to="../partnership">{{ $t('navPartnership')
                                }}</router-link>
                        </div>
                        <div class="one_user_link_wrap">
                            <router-link class="profile" to="../profile">{{ $t('navProfile') }}</router-link>
                        </div>
                    </nav>
                </div>
                <div class="user_body">
                    <router-view ref="userBoardRouterView" :userInfo="userInfo" :userSubscription="userSubscription"
                        @go-to-tariffs="goToTariffs" @togle-unsubscribe-popup="toggleUnsubscribePopup"
                        :cancelSubscribeIndex=cancelSubscribeIndex :opacityBody="opacityBody"
                        :promoTariffs="promoTariffs" :monthTariffs="monthTariffs"
                        :anualTariffs="anualTariffs"></router-view>
                </div>
            </div>
        </section>
    </main>
    <FooterVue></FooterVue>
    <modal-unsubscribe-popup ref="unsubscribePopup" v-show="isOpenedUnsubscribePopup"
        :unsubscribeTarifData="unsubscribeTarifData" @togle-unsubscribe-popup="toggleUnsubscribePopup"
        @complete-cancel-subscription="completeCancelSubscription" />
    <transition name="fade">
        <ModalProfileAction v-show="isOpenedProfileModal" @togle-profile-modal="toggleProfileModal()" />
    </transition>
    <notifications position="top right" />
</template>

<script>
    import axios from "axios"
    // import WorkboardView from '@/views/WorkboardView.vue'
    import HeaderVue from '@/components/common/Header.vue'
    import FooterVue from '@/components/common/Footer.vue'
    import ModalUnsubscribePopup from '@/components/common/ModalUnsubscribePopup.vue'
    import ModalProfileAction from '@/components/common/ModalProfileAction.vue'
    export default {
        name: "main-layout",
        components: {
            HeaderVue,
            FooterVue,
            ModalUnsubscribePopup,
            ModalProfileAction,
        },
        data() {
            return {
                baseBackendUrl: process.env.VUE_APP_BASE_URL,
                baseUserIdent: process.env.VUE_APP_USER_IDENT,
                isOpenedUnsubscribePopup: false,
                isOpenedProfileModal: false,
                unsubscribeTarifData: {},
                cancelSubscribeIndex: "",
                opacityBody: 0,
                userInfo: {
                    id: "17",
                    email: "vasyayaya@mail.ru",
                    name: "Jhonathan",
                    role: "user",
                    profile: "agency",
                    tarifs: [
                        {
                            id: "1",
                            name: "Free.",
                            price: 0,
                            usedLeads: 0,
                            maxLeadsCount: 100,
                            maxQuizzesCount: 0,
                            validity_months: 1,
                            created_at: 1670528835,
                            is_active: true,
                            start_at: 1670528835,
                            end_at: 1670528835,
                        }
                    ],
                    tarif: {},
                    period_starts_at: null,
                    payments: [
                        {
                            id: "6",
                            payment: "PT172022212_1670528631",
                            amount: 1,
                            created_at: 1670528835,
                            payment_system: "Stripe",
                            data: "{\"cardType\": \"Visa\"}",
                        }
                    ],
                    created_at: 1665096088,
                    quizCounter: 0,
                    leadCounter: 0,
                    isBlocked: false,
                    bonuses: [
                        {
                            start: 1665096088,
                            end: 1665096088,
                            leads_count: 0,
                            used: 0,
                            coupon: '',
                            created_at: 1665096088,
                            updated_at: 1665096088,
                        }
                    ],
                },
                payment: {
                    form: {
                        method: "POST",
                        action: "https://api.adsquiz.io/pay",
                        "accept-charset": "UTF-8"
                    },
                    hidden: {
                        tarif_id: 1,
                        is_subscription: 0
                    },
                    inputs: {
                        is_subscription: {
                            type: "checkbox",
                            value: 1,
                            checked: "checked"
                        },
                    }
                },
                userSubscription: {
                    id: "1",
                    tarif: {
                        id: "123",
                        name: "Pro",
                        price: "10",
                        validity_months: "1",
                    },
                    card: {
                        brand: "visa",
                        checks: {
                            address_line1_check: null,
                            address_postal_code_check: null,
                            cvc_check: "pass"
                        },
                        country: "US",
                        exp_month: 1,
                        exp_year: 2025,
                        fingerprint: "USa6Mr72mUVBGShu",
                        funding: "debit",
                        generated_from: null,
                        last4: "5556",
                        networks: {
                            available: ["visa"],
                            preferred: null
                        },
                        three_d_secure_usage: {
                            supported: true
                        },
                        wallet: null
                    },
                    amount: "10",
                    start_at: "1670763091",
                    next_payment: "1670764000",
                },
                tariffsData: [
                    {
                        id: "2",
                        sort: 1000,
                        maxLeadsCount: 300,
                        maxQuizzesCount: 0,
                        name: "Pro.",
                        payButton: {
                            form: {
                                method: "POST",
                                action: "https://api.adsquiz.io/pay",
                            },
                            hidden: {
                                tarif_id: 1,
                                is_subscription: 0
                            },
                            inputs: {
                                is_subscription: {
                                    type: "checkbox",
                                    value: 1,
                                    checked: "checked"
                                },
                            }
                        },
                        price: 20,
                        validity_months: 12
                    },
                ],
                promoTariffs: [
                    {
                        id: "1",
                        sort: 0,
                        maxLeadsCount: 300,
                        maxQuizzesCount: 0,
                        name: "",
                        payButton: {
                            form: {
                                method: "POST",
                                action: "https://api.adsquiz.io/pay",
                            },
                            hidden: {
                                tarif_id: 1,
                                is_subscription: 0
                            },
                            inputs: []
                        },
                        price: 20,
                        validity_months: 1,
                    }
                ],
                monthTariffs: [
                    {
                        id: "2",
                        sort: 1000,
                        maxLeadsCount: 300,
                        maxQuizzesCount: 0,
                        name: "",
                        payButton: {
                            form: {
                                method: "POST",
                                action: "https://api.adsquiz.io/pay",
                            },
                            hidden: {
                                tarif_id: 1,
                                is_subscription: 0
                            },
                            inputs: {
                                is_subscription: {
                                    type: "checkbox",
                                    value: 1,
                                    checked: "checked"
                                },
                            }
                        },
                        price: 20,
                        validity_months: 1,
                    }
                ],
                anualTariffs: [
                    {
                        id: "3",
                        sort: 1000,
                        maxLeadsCount: 300,
                        maxQuizzesCount: 0,
                        name: "",
                        payButton: {
                            form: {
                                method: "POST",
                                action: "https://api.adsquiz.io/pay",
                            },
                            hidden: {
                                tarif_id: 1,
                                is_subscription: 0
                            },
                            inputs: {
                                is_subscription: {
                                    type: "checkbox",
                                    value: 1,
                                    checked: "checked"
                                },
                            }
                        },
                        price: 20,
                        validity_months: 12,
                    }
                ],
            }
        },
        computed: {
        },
        methods: {
            getProfileInfo() {
                // Below first string for query
                // users(` + this.userRequestedIdentificator + `) {
                var data = JSON.stringify({
                    query: `query { 
                    users (` + this.baseUserIdent + `) {
                        id
                        email
                        name
                        role
                        profile
                        isBlocked
                        interface_lang
                        tarifs { 
                            id
                            name
                            price
                            usedLeads
                            maxLeadsCount
                            maxQuizzesCount
                            validity_months
                            created_at
                            is_active
                            start_at
                            end_at
                        }

                        period_starts_at

                        payments { 
                            id
                            payment_system
                            payment
                            amount
                            created_at
                            data
                        }
                        created_at
                        quizCounter
                        leadCounter
                        isBlocked
                        bonuses {
                            start
                            end
                            leads_count
                            used
                            coupon
                        }
                    }
                    userSubscriptions {
                        id
                        tarif { id name price validity_months}
                        card
                        amount
                        start_at
                        next_payment
                    }
                    tarifs {
                        id
                        sort
                        maxLeadsCount
                        maxQuizzesCount
                        name
                        payButton
                        price
                        validity_months
                    }
                }`,
                    variables: {}
                });

                var config = {
                    method: 'post',
                    url: this.baseBackendUrl + '/graphql',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios(config)
                    .then(response => {
                        this.userInfo = response.data.data.users[0]
                        let tariffs = response.data.data.tarifs
                        this.tariffsData = []

                        tariffs = tariffs.filter(item => item.payButton !== null);

                        tariffs.forEach((item, i) => {
                            if (item.payButton) {
                                try {
                                    tariffs[i].payButton = JSON.parse(item.payButton);
                                } catch (e) {
                                    console.error(`Ошибка парсинга payButton у тарифа с id ${item.id}:`, e);
                                }
                            }
                        });

                        if (response.data.data.userSubscriptions.length) {
                            const transformedArray = response.data.data.userSubscriptions[0]
                            transformedArray.card = JSON.parse(response.data.data.userSubscriptions[0].card);
                            this.userSubscription = transformedArray
                        } else {
                            this.userSubscription = null
                        }

                        this.promoTariffs = [];
                        this.monthTariffs = [];
                        this.anualTariffs = [];
                        const pendingPromoTariffs = [];
                        const pendingAnualTariffs = [];

                        // Распределение тарифов по условиям
                        tariffs.forEach(item => {
                            if (item.sort < 100) {
                                // Promo tariffs logic
                                const matchingMonthTariff = this.monthTariffs.find(
                                    monthTariff => monthTariff.maxLeadsCount === item.maxLeadsCount
                                );

                                if (matchingMonthTariff) {
                                    item.month_price = matchingMonthTariff.price;
                                } else if (item.maxLeadsCount >= 100000) {
                                    // Find first monthTariff with maxLeadsCount >= 100000
                                    const firstEligibleMonthTariff = this.monthTariffs.find(
                                        monthTariff => monthTariff.maxLeadsCount >= 100000
                                    );
                                    if (firstEligibleMonthTariff) {
                                        item.month_price = firstEligibleMonthTariff.price;
                                    } else {
                                        // Add to pending promo tariffs for later resolution
                                        pendingPromoTariffs.push(item);
                                    }
                                } else {
                                    // Add to pending promo tariffs for later resolution
                                    pendingPromoTariffs.push(item);
                                }

                                this.promoTariffs.push(item);
                            } else if (item.validity_months === 1) {
                                this.monthTariffs.push(item);

                                // Update pending promo tariffs with matching monthTariff maxLeadsCount
                                pendingPromoTariffs.forEach((pendingPromo) => {
                                    if (pendingPromo.maxLeadsCount === item.maxLeadsCount || pendingPromo.maxLeadsCount >= 100000) {
                                        pendingPromo.month_price = item.price;
                                    }
                                });

                                // Check if any pending annual tariffs match this name
                                pendingAnualTariffs.forEach((pendingAnual) => {
                                    if (pendingAnual.name === item.name) {
                                        pendingAnual.month_price = item.price;
                                    }
                                });
                            } else if (item.validity_months === 12) {
                                // Check for corresponding monthly tariff
                                const matchingMonthTariff = this.monthTariffs.find(monthTariff => monthTariff.name === item.name);
                                if (matchingMonthTariff) {
                                    item.month_price = matchingMonthTariff.price;
                                } else {
                                    // Add to pending annual tariffs for later resolution
                                    pendingAnualTariffs.push(item);
                                }
                                this.anualTariffs.push(item);
                            }
                        });

                        // Handle remaining pending promo tariffs for fallback logic
                        pendingPromoTariffs.forEach(pendingPromo => {
                            const closestMatchingMonthTariff = this.monthTariffs.find(
                                monthTariff => monthTariff.maxLeadsCount >= pendingPromo.maxLeadsCount
                            );
                            if (closestMatchingMonthTariff) {
                                pendingPromo.month_price = closestMatchingMonthTariff.price;
                            }
                        });




                        this.tariffsData = tariffs
                        this.opacityBody = 1
                        this.errorAuth(response.status)
                    })
                    .catch(error => {
                        console.log(error)
                        this.errorAuth(error.response.status)
                    })

            },
            mountedLang(interfaceLang) {
                var htmlElement = document.getElementsByTagName('html')[0]
                htmlElement.setAttribute('lang', interfaceLang);
            },
            goToTariffs() {
                this.$router.push({ name: 'tariffs-page' })
            },
            completeCancelSubscription() {
                this.$notify({
                    title: this.$t('subscriptionCanceledTitle'),
                    type: 'success'
                });
                this.cancelSubscribeIndex = "0"
            },
            toggleUnsubscribePopup(id) {
                this.isOpenedUnsubscribePopup = !this.isOpenedUnsubscribePopup
                if (id) {
                    this.unsubscribeTarifData = {id: id}
                }
            },
            toggleProfileModal() {
                this.isOpenedProfileModal = !this.isOpenedProfileModal
            },
        },
        mounted() {
            this.isOpenedProfileModal = false
            this.getProfileInfo()
            this.mountedLang(this.$i18n.locale)
        },
        beforeRouteUpdate() {
            this.isOpenedProfileModal = false
        },
        watch: {
        }
    }

</script>

<style lang="css">
.user-board>.container {
    margin: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
}
.user-board .user_body {
    background: var(--color-main-light-gray);
}
.user-board .user_body>.main-board {
    display: flex;
    flex-direction: column;
    background: var(--color-main-white);
}
.user-board .user_body>.tariffs-board {
    background: transparent;
}
.user_control {
    height: 100%;
    width: 356px;
    border-radius: 0;
    padding: 16px 24px 16px 28px;
}

.user_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: calc(100% - 356px);
    padding: 20px 28px 20px 20px;
    margin: 0 auto;
    position: relative;
    z-index: 100;
}
.user-board_menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
}
.one_user_link_wrap {
    width: 100%;
    padding: 8px 0;
}
.one_user_link_wrap_partnership {
    display: none;
}
.user-board_menu a {
    display: block;
    width: 100%;
    padding: 12px;
    border-radius: 4px;
}
.profile-board>.container {
    margin: 0;
    width: 100%;
    max-width: 100%;
}
.b-p-p-board_header {
    width: 100%;
    margin-bottom: 16px;
}
.b-p-p-board_body {
    width: 100%;
    height: 100%;
    max-height: 100%;
}
.b-p-p-board_prof-info {
    width: 100%;
    padding: 24px 24px 48px 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.p-b_p-i_section_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.p-b_p-i_review {
    width: 250px;
}
.p-b_p-i_review_header {
    margin-bottom: 4px;
}
.p-b_p-i_review_paragraph {
    margin-bottom: 16px;
}
.p-b_p-i_review_paragraph:last-of-type {
    margin-bottom: 0;
}
.p-b_p-i_inputs {
    width: calc(100% - 290px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
}
.p-b_wrap-item {
    width: calc(50% - 12px);
}
.b-p_general_point-wrap {
    background-color: var(--color-main-light-gray)!important;
}
.p-b_input-wrap {
    width: 100%;
}
.p-b_input-wrap label {
    width: 100%;
    margin-bottom: 4px;
}
.p-b_input-wrap .p-b_profile_input, 
.p-b_input-wrap .p-b_profile_select{
    width: 100%;
}
.p-b_p-i_buttons {
    display: flex;
    gap: 16px;
    margin-left: 290px;
}
.btn-save_user {
    width: auto!important;
}
.p-b_p-i_promo {
    padding-top: 20px;
}
.btn-save_promo {
    height: 48px!important;
}

@media screen and (max-width: 1100px) {
    .user_control {
        width: 280px;
    }
    .user_body {
        width: calc(100% - 280px);
    }
    .p-b_p-i_review {
        width: 200px;
    }
    .p-b_p-i_inputs {
        width: calc(100% - 224px);
    }
    .p-b_p-i_buttons {
        margin-left: 224px;
    }
}

@media screen and (max-width: 920px) {
    .user_control {
        width: 240px;
    }
    .user_body {
        width: calc(100% - 240px);
    }
    .p-b_p-i_review {
        width: 156px;
    }
    .p-b_p-i_inputs {
        width: calc(100% - 180px);
    }
    .p-b_p-i_buttons {
        margin-left: 180px;
    }
}

@media screen and (max-width: 860px) {
    .b-p-p-board_prof-info {
        gap: 24px;
    }
    .p-b_p-i_inputs {
        gap: 4px;
    }
    .p-b_wrap-item {
        width: 100%;
    }
    .btn-save_user {
        width: 100%!important;
    }
}

@media screen and (max-width: 850px) {
    .user_control {
        width: 140px;
        padding: 16px;
    }
    .user_body {
        width: calc(100% - 140px);
        padding: 16px;
    }
    .p-b_p-i_section_wrap {
        flex-direction: column;
        gap: 24px;
    }
    .p-b_p-i_review {
        width: 100%;
    }
    .p-b_p-i_inputs {
        width: 100%;
    }
    .p-b_p-i_buttons {
        flex-direction: column;
        margin-left: 0;
    }
}

.billing-board .b-p-p-board_body {
    height: 100%;
}
/* .board-biling_page-wrap {
    height: 100%;
} */
.non_bill_wrap {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.t-billing_body {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
}
.t-billing_table-header {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.t-billing_table_body {
    height: calc(100% - 40px);
}
.t-billing_th {
    display: flex;
    align-items: center;
    width: calc((100%) / 4);
    padding: 8px 16px;
}
.t-billing_th.t-billing_th_date {
    width: calc(((100%) / 4) - 8%);
}
.t-billing_th.t-billing_th_cost {
    width: calc(((100%) / 4) - 12%);
}
.t-billing_th.t-billing_th_id {
    width: calc(((100%) / 4) + 15%);
}
.t-billing_th.t-billing_th_payment_method {
    width: calc(((100%) / 4) + 5%);
}
.t-billing_th p {
    display: block;
}
.t-billing_th_date {
    display: flex;
}
.t-billing_tr {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.t-billing_tr>div {
    display: block;
    width: calc((100%) / 4);
    padding: 20px 14px;
    background-color: transparent;
}
.t-billing_tr>div.billing_date {
    width: calc(((100%) / 4) - 8%);
}
.t-billing_tr>div.billing-price {
    width: calc(((100%) / 4) - 12%);
}
.t-billing_tr>div.billing-id {
    width: calc(((100%) / 4) + 15%);
}
.t-billing_tr>div.billing-id p {
    overflow: hidden;
    text-overflow: ellipsis;
}
.t-billing_tr>div.method_wrap {
    width: calc(((100%) / 4) + 5%);
}
.t-billing_tr>div.method_wrap p {
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .t-billing_tr>div.payment_action {
    width: 64px;
    padding: 16px;
} */
@media screen and (max-width: 820px) {
.t-billing_tr>div {
    padding: 4px 8px;
}
/* .t-billing_tr>div.billing_date {
    width: calc(((100% - 64px) / 4) - 8%);
}
.t-billing_tr>div.billing-price {
    width: calc(((100% - 64px) / 4) - 12%);
}
.t-billing_tr>div.billing-id {
    width: calc(((100% - 64px) / 4) + 15%);
}
.t-billing_tr>div.method_wrap {
    width: calc(((100% - 64px) / 4) + 5%);
} */
}
.b-b_bonuses {
    width: 100%;
}
.promocode_input-wrap {
    display: flex;
    align-items: center;
    width: 100%;
}

.b-b_bonuses input[type="text"] {
    width: 100%;
}

.b-b_bonuses input[type="button"] {
    text-align: center;
    cursor: pointer;
}



@media screen and (max-width: 960px) {
    .billing-date {
        font-size: 12px;
    }
    .billing-id_number {
        font-size: 12px;
    }
    .method_wrap p {
        font-size: 12px;
    }
}

@media screen and (max-width: 720px) {
    .user_control {
        width: 64px;
        padding: 16px;
    }
    .user_body {
        width: calc(100% - 64px);
        /* padding: 16px 0; */
        background-color: var(--color-main-white);
        border-radius: 0;
    }
    .user-board .container {
        max-width: 100%;
    }
    .user-board .billing-board {
        padding: 16px;
    }
    .user_body .user-board {
        background-color: var(--color-main-white);
    }
    .user-board_menu a {
        overflow: hidden;
        font-size: 0;
        height: 32px;
        background-size: 70%;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .user-board_menu a.billing {
        background-image: url('~/public/img/icons/credit-card.svg');
    }
    .user-board_menu a.profile {
        background-image: url('~/public/img/icons/profile_settings.svg');
    }
    .user-board_menu a.tariffs {
        background-image: url('~/public/img/icons/tariffs.svg');
    }
    .b-p-p-board_header {
        padding: 0 16px;
    }
    .user-board_back_to_workboard_wrap {
        height: 24px;
    }
    .user-board_back_to_workboard_wrap .back-to-work-board {
        height: 100%;
        font-size: 0;
        padding: 16px;
        background-position: center;
    }
    .b-p-p-board_prof-info {
        padding: 16px;
        border: none!important;
    }
    
}


</style>